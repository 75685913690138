@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Playwrite+GB+S:ital,wght@0,100..400;1,100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito Sans', sans-serif;
}
.navbar {
    background-color: #f1f1f1; 
    display: flex;
    justify-content: space-between; 
    align-items: center;
    height: 90px;
    padding: 0 2% 0 2%;
  }

  /* .mobile-logo{
    width: 18% !important;
    height: 15% !important;
  } */
  
  .navbar img {
    display: block;
    margin: 0 auto; 
  }
  .forum-menu.active{
    color: black !important;
  }
  
  .navbar .bi-geo-alt-fill {
    font-size: 18px; 
  }
  .product-card{
    border: #808080 solid 1px !important;
    border-radius: 10px !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    transform: translateY(0); 
  }

  .product-card:hover{
    transform: translateY(-10px);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 25px;
  }

  .product-card span{
    border-bottom: 0.5px solid #cac9c9;
  }

  .add-to-card-button{
    background-color: #0024FF;
    color: white;
  }

  .add-to-card-button:hover{
    background-color: #0024FF;
    color: white;
  }

  .feedviews-search-bar {
    height: 50px;
  }
  
  .feedviews-search-bar .vr {
    height: 40px;
    background-color: #808080;
  }

  .add-post-button {
    background-color: #F24E1E;
    color: white;
    height: 45px;
  }
  .add-post-button:hover{
    background-color: #F24E1E;
    color: white;
  }
  .add-post-button:active{
    background-color: #F24E1E !important;
    color: white !important;
  }
  .requirment-btn{
    background-color: #F24E1E !important;
    color: white !important;
  }
  .profile-update-btn{
    background-color: #F24E1E !important;
  }
  /* Location search  bar */
  .location_search_links li a:hover{
    color: #0024FF !important;
  }

  .feed_views_search div{
    justify-content: flex-start !important;
    margin-top: 0 !important;
  }
  .feed_views_search .inner_div{
    width: 100% !important;
  }
  .container.feed_views_main{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .addtocart-count{
    position: relative !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px !important;
    height: 50px !important;
  }
  .addtocart-count-icon{
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
  }
  /* Feed Views */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050; /* Make sure it's above other elements */
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px !important;
    position: relative;
  }
  .card-title{
    cursor: pointer;
  }
  
  /* Cart */
  .cart-container {
    max-width: 960px;
    margin: auto;
  }
  
  .img-thumbnail {
    object-fit: cover;
  }
  
  .border-bottom {
    border-color: #ddd !important;
  }

  .trash{
    cursor: pointer;
    color: #FF0000;
    border: none !important;
    background: none !important;
  }
  .card-img-top{
    /* width: 300px; */
    height: auto !important;
  }
  /* Store Details */
  .view-mobile-no{
    color: #0024FF;
  }

  .view-mobile-no:hover{
    color: #0024FF;
  }

  .contact-supplier-btn{
    background: #F24E1E;
    color: white;
  }

  .contact-supplier-btn:hover{
    background: #F24E1E;
    color: white;
  }

  /* Order history */
  .table-row:hover {
    background-color: #f8f9fa; /* Subtle hover effect */
  }
  .accordion-flush .border-bottom {
    margin: 0 !important;
  }
  @media (max-width: 1200px) {
    /* Store Details */
 .view-mobile-no{
   font-size: 12px !important;
 }
 .view-mobile-no img{
   width: 20px;
   height: 20px;
 }
 }
 
  @media (max-width: 992px) {
    .forum-button{
      margin-bottom: 30%;
    }
    .feed_views_search .inner_div{
      width: 100% !important;
    }
    .feed_views_search{
      width: 100% !important;
    }
    .container.feed_views_main{
      width: 100% !important;
      /* margin: 0 !important; */
    }
    .feed_views_search div {
      justify-content: center !important;
    }
    .add-post-btn-div{
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px !important;
    }
    .add-post-button{
      width: 50% !important;
    }
    .add-forum-button{
      width: 50% !important;
    }
  }

  @media (max-width: 768px)
  {
    .forum-button{
      width: 30% !important;
      margin-bottom: 0 !important;
    }
    .forum-div{
      width: 100% !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
    .location_searchbar {
        display: none !important;
    }
    .add-post-button{
      justify-content: center !important;
      align-items: center !important;
    }
    .view-mobile-no{
      font-size: 12px !important;
    }
    .view-mobile-no img{
      width: 20px;
      height: 20px;
    }
    .card-img-top{
     /* width: 300px; */
     height: auto !important;
   }
  }

  /* Styles for small devices (below 576px) */
@media (max-width: 576px) {
  .card-title, .card-text, small {
    font-size: 0.8rem;
  }
  .btn {
    font-size: 0.8rem; 
    padding: 0.4rem 0.4rem; 
  }
  .view-mob-num{
    height: 15px !important;
  }

  .view-mobile-no{
    font-size: 9px !important;
  }
}
  
